
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import InfoModal from "@/components/modals/forms/entity/InfoModal.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "entity info",
  components: {
    Datatable,
    InfoModal,
  },
  data() {
    return {
      tableHeader: [
        {
          name: "sl",
          key: "sl",
          sortable: true,
        },
        {
          name: "District",
          key: "district",
          sortable: true,
        },
        {
          name: "Male",
          key: "male",
          sortable: true,
        },
        {
          name: "Female",
          key: "female",
          sortable: true,
        },
        {
          name: "Total",
          key: "total",
          sortable: true,
        },
        // {
        //   name: "Actions",
        //   key: "actions",
        //   sortable: false,
        // },
      ],
      lists: [],
      search: "",
      tableData: [
        {
          sl: 1,
          district: "Dhaka",
          male: 20,
          female: 30,
          total: 50,
        },
        {
          sl: 2,
          district: "Dhaka",
          male: 20,
          female: 30,
          total: 50,
        },
      ],
      data: {},
    };
  },
  // async created() {
  //   await this.getData();
  //   Object.assign(this.tableData, this.lists);
  //   this.emitter.on("infos-updated", async () => {
  //     await this.getData();
  //     Object.assign(this.tableData, this.lists);
  //   });
  // },
  methods: {
    // async getData() {
    //   await ApiService.get("entity/infos")
    //     .then((response) => {
    //       this.lists = response.data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // },
    // searchItems() {
    //   if (this.search !== "") {
    //     let results: never[] = [];
    //     for (let j = 0; j < this.tableData.length; j++) {
    //       if (this.searchingFunc(this.tableData[j], this.search)) {
    //         results.push(this.tableData[j]);
    //       }
    //     }
    //     this.tableData.splice(0, this.tableData.length, ...results);
    //   } else {
    //     Object.assign(this.tableData, this.lists);
    //   }
    // },
    // searchingFunc(obj, value) {
    //   for (let key in obj) {
    //     if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
    //       if (obj[key].indexOf(value) != -1) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // },
    // edit(data) {
    //   this.emitter.emit("edit-modal-data", data);
    // },
    // add() {
    //   this.emitter.emit("add-modal-data", true);
    // },
    // view(infos) {
    //   this.data = infos;
    // },
    // Delete(id) {
    //   Swal.fire({
    //     title: "Are you sure you want to delete it?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Yes, delete!",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       ApiService.delete("entity/infos/" + `${id}`)
    //         .then((response) => {
    //           this.emitter.emit("infos-updated", true);
    //           Swal.fire("Deleted!", response.data.message, "success");
    //         })
    //         .catch(({ response }) => {
    //           console.log(response);
    //         });
    //     }
    //   });
    // },
  },
});
